@import "../scss/variables";

/* ###### Closed-sidemenu ###### */
@media (min-width: 768px) {
	.app.sidenav-toggled {
	  .app-content {
		margin-left: 0;
	  }
  
	  .app-sidebar {
		left: -230px !important;
	  }
  
	  .app-sidebar__overlay {
		visibility: hidden;
	  }
	}
  }
  
  @media (max-width: 767px) {
	.app {
	  overflow-x: hidden;
  
	  .app-sidebar {
		left: -230px;
	  }
  
	  .app-sidebar__overlay {
		visibility: hidden;
	  }
  
	  &.sidenav-toggled {
		.app-content {
		  margin-left: 0;
		}
  
		.app-sidebar {
		  left: 0;
		}
  
		.app-sidebar__overlay {
		  visibility: visible;
		}
	  }
	}
  }
  
  .app-content {
	min-height: calc(100vh - 50px);
	margin-top: 49px;
	margin-bottom: 0 !important;
	-webkit-transition: margin-left 0.3s ease;
	-o-transition: margin-left 0.3s ease;
	transition: margin-left 0.3s ease;
	overflow: hidden;
	padding: 18px 1.2rem 1.2rem;
  
	.side-app {
	  padding: 25px 1.5rem 0 1.5rem;
	}
  }
  
  .footer .container {
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	margin-right: auto;
	margin-left: auto;
  }
  
  @media (min-width: 768px) {
	.app-content {
	  margin-left: 230px;
	}
  }
  
  @media (max-width: 767px) {
	.app-content {
	  margin-top: 50px;
	  min-width: 100%;
	  padding: 9px 0.75rem 0 0.75rem;
	}
  
	.app-sidebar {
	  margin-top: 50px !important;
	}
  }
  
  @media print {
	.app-content {
	  margin: 0;
	  padding: 0;
	  background-color: $white;
	}
  }
  
  .light-text {
	font-weight: 300 !important;
  }
  
  .semibold-text {
	font-weight: 600 !important;
  }
  
  .line-head {
	padding-bottom: 10px;
	border-bottom: 1px solid #ddd;
  }
  
  /*----- Componant: Top Navigation Bar ----- */
  
  .app-header {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	z-index: 999;
	padding-right: 15px;
	padding: 0.75rem 0;
	border-bottom: 1px solid  $border;
  }
  
  @media (min-width: 768px) {
	.app-header {
	  padding: 0.75rem 0px !important;
	  padding-right:25px !important;
	}
  }
  
  @media print {
	.app-header {
	  display: none;
	}
  }
  
  .app-header__logo {
	-webkit-box-flex: 1;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	color: $white;
	text-align: center;
	font-family: 'Niconne';
	padding: 0 15px;
	font-size: 26px;
	font-weight: 400;
	line-height: 50px;
  
	&:focus, &:hover {
	  text-decoration: none;
	}
  }
  
  @media (min-width: 768px) {
	.app-header__logo {
	  -webkit-box-flex: 0;
	  -ms-flex: 0 0 auto;
	  flex: 0 0 auto;
	  display: block;
	  width: 230px;
	}
  }
  
  .app-sidebar__toggle {
	padding: 0 15px;
	color: $color;
	line-height: 2.4;
	-webkit-transition: background-color 0.3s ease;
	-o-transition: background-color 0.3s ease;
	transition: background-color 0.3s ease;
	z-index: 1;
  
	&:hover {
	  color: $color;
	}
  
	&:before {
	  content: "\e907";
	  transition: all .25s;
	  font-size: 21px;
	  font-family: 'feather';
	}
  
	&:focus, &:hover {
	  text-decoration: none;
	}
  }
  
  /*@media (max-width: 360px) {
	  .app-sidebar__toggle {
		  line-height: 2;
	  }
  }*/
  @media (max-width: 767px) {
	.app-sidebar__toggle {
	  -webkit-box-ordinal-group: 0;
	  -ms-flex-order: -1;
	  order: -1;
	}
  }
  
  .app-nav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
  }
  
  @media (min-width: 768px) {
	.app-nav {
	  -webkit-box-flex: 1;
	  -ms-flex: 1 0 auto;
	  flex: 1 0 auto;
	}
  }
  
  .app-nav__item {
	display: block;
	padding: 15px;
	line-height: 20px;
	color: #a8a8a8;
	-webkit-transition: background-color 0.3s ease;
	-o-transition: background-color 0.3s ease;
	transition: background-color 0.3s ease;
  
	&:hover, &:focus {
	  background: rgba(0, 0, 0, 0.1);
	  color: #f6f6f6;
	}
  }
  
  .app-search {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-item-align: center;
	align-self: center;
	margin-right: 15px;
	padding: 10px 0;
  }
  
  @media (max-width: 480px) {
	.app-search {
	  display: none;
	}
  }
  
  .app-search__input {
	border: 0;
	padding: 5px 10px;
	padding-right: 30px;
	border-radius: 2px;
	background-color: rgba(255, 255, 255, 0.8);
	-webkit-transition: background-color 0.3s ease;
	-o-transition: background-color 0.3s ease;
	transition: background-color 0.3s ease;
  
	&::-webkit-input-placeholder, &:-ms-input-placeholder, &::-ms-input-placeholder, &::placeholder {
	  color: rgba(0, 0, 0, 0.4);
	}
  }
  
  .app-search__button {
	position: absolute;
	right: 0;
	top: 10px;
	bottom: 10px;
	padding: 0 10px;
	border: 0;
	color: rgba(0, 0, 0, 0.8);
	background: none;
	cursor: pointer;
  }
  
  .app-notification {
	min-width: 270px;
  }
  
  .app-notification__title {
	padding: 8px 20px;
	text-align: center;
	background-color: rgba(0, 150, 136, 0.4);
	color: #333;
  }
  
  .app-notification__footer {
	padding: 8px 20px;
	text-align: center;
	background-color: #eee;
  }
  
  .app-notification__content {
	max-height: 220px;
	overflow-y: auto;
  
	&::-webkit-scrollbar {
	  width: 6px;
	}
  
	&::-webkit-scrollbar-thumb {
	  background: rgba(0, 0, 0, 0.2);
	}
  }
  
  .app-notification__item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 8px 20px;
	color: inherit;
	border-bottom: 1px solid #ddd;
	-webkit-transition: background-color 0.3s ease;
	-o-transition: background-color 0.3s ease;
	transition: background-color 0.3s ease;
  
	&:focus, &:hover {
	  color: inherit;
	  text-decoration: none;
	  background-color: #e0e0e0;
	}
  }
  
  .app-notification__message, .app-notification__meta {
	margin-bottom: 0;
  }
  
  .app-notification__icon {
	padding-right: 10px;
  }
  
  .app-notification__message {
	line-height: 1.2;
  }
  
  .app-sidebar {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	margin-top: 64px;
	width: 230px;
	max-height: 100%;
	z-index: 99;
	background: $white;
	-webkit-transition: left 0.3s ease, width 0.3s ease;
	-o-transition: left 0.3s ease, width 0.3s ease;
	transition: left 0.3s ease, width 0.3s ease;
	box-shadow: 0 0 0 1px rgba(61, 119, 180, 0.12), 0 8px 16px 0 rgba(91, 139, 199, 0.24);
  }
  
  .user-info h6 {
	color: $color;
  }
  
  .app-sidebar {
	&::-webkit-scrollbar {
	  width: 6px;
	}
  
	&::-webkit-scrollbar-thumb {
	  background: rgba(0, 0, 0, 0.2);
	}
  }
  
  @media print {
	.app-sidebar {
	  display: none;
	}
  }
  
  @media (max-width: 767px) {
	.app-sidebar__overlay {
	  position: fixed;
	  top: 0;
	  left: 0;
	  bottom: 0;
	  right: 0;
	  z-index: 9;
	}
  }
  
  .app-sidebar__user {
	.dropdown-menu {
	  top: 10px !important;
	}
  
	img {
	  padding: 5px;
	}
  
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	color: #8e9cad;
	width: 100%;
	padding: 12px 17px;
	display: inline-block;
	border-bottom: 1px solid  $border;
  }
  
  .app-sidebar__user-avatar {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	margin-right: 15px;
  }
  
  .app-sidebar__user-name {
	&.text-sm {
	  font-size: 12px;
	  font-weight: 400;
	}
  
	font-size: 17px;
	line-height: 1.3;
	white-space: nowrap;
	overflow: hidden;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	margin-bottom: 0;
	overflow: hidden;
	font-weight: 600;
	color: #e5e9ec;
	font-size: 15px;
	margin-top: 5px !important;
  }
  
  .app-sidebar__user-designation {
	white-space: nowrap;
	overflow: hidden;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	margin-bottom: 0;
	overflow: hidden;
	font-weight: 600;
	color: #e5e9ec;
	font-size: 15px;
	margin-top: 5px !important;
  }
  
  .side-menu {
	margin-bottom: 0;
	padding-bottom: 40px;
	padding: 0;
  }
  
  .side-menu__item {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 8px 19px 8px 25px;
	font-size: 14px;
	font-weight: 500;
	-webkit-transition: border-left-color 0.3s ease, background-color 0.3s ease;
	-o-transition: border-left-color 0.3s ease, background-color 0.3s ease;
	transition: border-left-color 0.3s ease, background-color 0.3s ease;
	color: $color;
	border-bottom: 1px solid $border;
  
	&.active, &:hover, &:focus {
	  text-decoration: none;
	  color: $white;
	  background: rgba(236, 238, 249, 0.9);
	}
  
	&.active .side-menu__icon, &:hover .side-menu__icon, &:focus .side-menu__icon {
	  color: $white;
	}
  }
  
  .slide-item {
	&.active, &:hover, &:focus {
	  text-decoration: none;
	  color: #b48ef4;
	}
  }
  
  .slide-menu a.active {
	color: $primary;
	background: $white;
  }
  
  .slide-item {
	&.active, &:hover, &:focus {
	  text-decoration: none;
	  color: $primary;
	  background: $white;
	}
  }
  
  .slide.is-expanded a {
	/*color: $color;*/
	text-decoration: none;
  }
  
  .side-menu .side-menu__icon {
	color: $color;
	font-size: 16px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	vertical-align: middle;
	width: 30px !important;
	border-radius: 50%;
	margin-left: -8px;
  }
  
  .side-menu__icon {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	width: 25px;
  }
  
  .side-menu__label {
	white-space: nowrap;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
  }
  
  .slide.is-expanded {
	.slide-menu {
	  li a:hover {}
  
	  max-height: 100vh;
	  -webkit-transition: max-height 0.3s ease;
	  -o-transition: max-height 0.3s ease;
	  transition: max-height 0.3s ease;
	}
  
	.angle {
	  -webkit-transform: rotate(90deg);
	  -ms-transform: rotate(90deg);
	  transform: rotate(90deg);
	}
  }
  
  .slide-menu {
	max-height: 0;
	overflow: hidden;
	-webkit-transition: max-height 0.3s ease;
	-o-transition: max-height 0.3s ease;
	transition: max-height 0.3s ease;
	padding: 0;
	font-size: .8rem !important;
  }
  
  .slide-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 10px 14px 10px 50px;
	font-size: 13px;
	color: $color;
	border-bottom: 1px solid rgba(67, 87, 133, 0.06);
  }
  
  .slide-menu li:last-child .slide-item {
	border-bottom: 1px solid  $border;
  }
  
  .slide-item .icon {
	margin-right: 5px;
  }
  
  .angle {
	-webkit-transform-origin: center;
	-ms-transform-origin: center;
	transform-origin: center;
	-webkit-transition: -webkit-transform 0.3s ease;
	transition: -webkit-transform 0.3s ease;
	-o-transition: transform 0.3s ease;
	transition: transform 0.3s ease;
	transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  }
  
  .app.sidebar-mini .side-menu_label {
	display: none;
  }
  
  .dropdown-menu {
	border-radius: 0;
  
	&.dropdown-menu-right {
	  left: auto;
	}
  }
  
  .dropdown-item {
	.fa, .icon {
	  vertical-align: middle;
	}
  }
  
  .app-title {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	background-color: #FFF;
	margin: -30px -30px 30px;
	padding: 20px 30px;
	-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  
	h1 {
	  margin: 0;
	  font-size: 24px;
	  font-weight: 400;
	}
  
	p {
	  margin-bottom: 0;
	  font-style: italic;
	}
  }
  
  @media print {
	.app-title {
	  display: none;
	}
  }
  
  @media (max-width: 480px) {
	.app-title {
	  margin: -15px -15px 15px;
	  padding: 20px;
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -ms-flex-direction: column;
	  flex-direction: column;
	  -webkit-box-align: start;
	  -ms-flex-align: start;
	  align-items: flex-start;
	}
  }
  
  @media (max-width: 480px) {
	.app-title p {
	  display: none;
	}
  }
  
  .app-breadcrumb {
	margin-bottom: 0;
	text-align: right;
	font-weight: 500;
	font-size: 13px;
	text-transform: capitalize;
	padding: 0;
	text-align: left;
	padding: 0;
	background-color: transparent;
  }
  
  @media (max-width: 480px) {
	.app-breadcrumb {
	  margin-top: 10px;
	}
  }
  
  @media print {
	.tile {
	  border: 1px solid #ddd;
	}
  }
  
  .app-sidebar {
	.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
	  background: rgba(0, 0, 0, 0.05);
	}
  
	.mCSB_scrollTools {
	  .mCSB_dragger .mCSB_dragger_bar, .mCSB_draggerRail {
		background: rgba(255, 255, 255, 0.2);
	  }
	}
  }
  
  .slide-menu a:before {
	content: '\e048';
	margin-right: 5px;
	position: relative;
	font-family: 'typicons' !important;
	opacity: 0.6;
	font-size: 12px;
  }
  
  .nav-badge {
	border-radius: 30px;
	padding: 0.4em 0.6em;
	font-size: 12px;
  }
  
  .header-secondary {
	margin: 0 -12px;
	border-bottom: 1px solid #eae8ef;
  }
  
  @media (min-width: 992px) {
	.header.app-header .form-inline {
	  margin-left: 0;
	}
  
	.toggle {
	  margin-left: 13rem;
	}
  
	.color-headerlogo {
	  margin-left: 1rem;
	}
  }
  
  @media (max-width: 568px) {
	.app-content {
	  margin-top: 44px;
	}
  }
  
  .header.app-header .form-control.header-search {
	margin-top: 2px;
  }
  
  @media (max-width: 575.98px) {
	.app-header .header-brand-img.mobile-logo {
	  display: block;
	  margin-left: 0;
	}
  
	.app-content .side-app {
	  padding: 25px 0.75rem 0 0.75rem;
	}
  
	.app-sidebar {
	  margin-top: 75px;
	}
  }
  
  @media (max-width: 992px) and (min-width: 768px) {
	.app-header {
	  .header-brand-img.desktop-lgo, .header-brand {
		margin-left: 0;
	  }
	}
  }
  
  .footer {
	transition: margin-left 0.3s ease;
  }
  
  @media (min-width: 992px) {
	.header-desktop {
	  display: block;
	  position: absolute;
	  width: 150px;
	  height: 40px;
	  background-repeat: no-repeat;
	  margin-top: 0px;
	}
  
	.color-headerlogo .header-mobile {
	  display: none;
	}
  
	.nav-link.icon.toggle {
	  margin-top: 1px;
	}
  }
  
  @media (max-width: 992px) and (min-width: 767px) {
	.header .form-inline {
	  margin-left: 13rem;
	}
  
	.header-desktop {
	  margin-left: 3rem;
	  display: block;
	  position: absolute;
	  width: 150px;
	  height: 40px;
	  background-repeat: no-repeat;
	  margin-top: 2px;
	}
  
	.search-icon {
	  margin-top: 3px;
	}
  
	.header .form-inline {
	  margin-left: 9rem;
	}
  }
  
  @media (max-width: 767.98px) and (min-width: 576px) {
	.header-desktop {
	  margin-left: 3rem;
	  display: block;
	  position: absolute;
	  width: 150px;
	  height: 40px;
	  background-repeat: no-repeat;
	  margin-top: 2px;
	}
  
	.search-icon {
	  margin-top: 3px;
	}
  }
  
  @media (max-width: 575.98px) {
	.color-headerlogo .header-mobile {
	  display: block;
	  position: absolute;
	  width: 50px;
	  height: 42px;
	  background-repeat: no-repeat;
	  margin-left: 3rem;
	  margin-top: 0px;
	}
  }
  
  .app-sidebar .ps__thumb-y {
	right: -1px;
  }
  
  .side-menu__item {
	&.active, &:hover, &:focus, &.active .side-menu__icon, &:hover .side-menu__icon, &:focus .side-menu__icon {
	  color: $primary;
	}
  }
  
  .slide-menu a.active {
	color: $primary !important;
	background: rgba(236, 238, 249, 0.3);
  }
  
  .slide-item {
	&.active, &:hover, &:focus {
	  color: $color;
	  background: rgba(236, 238, 249, 0.3);
	}
  
	&.active .side-icon, &:hover .side-icon {
	  color: $color !important;
	}
  }
  
  .slide.submenu .side-menu__label:hover {
	color: $color;
  }
  
  .side-menu .slide-menu.submenu-list a:hover {
	background: $white;
	color: $primary;
  }
  
  /* ###### Closed-sidemenu ###### */